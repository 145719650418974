import React from "react";
import { useTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";
import Card from "./Card";
import TextImageIsRight from "../Solutions/TextImageIsRight";
import styles from "./AlignTeams.module.css"
const PlanProjects = () => {
  const { t } = useTranslation();

  const title1 = (
    <h2
      style={{
        color: "rgb(242, 242, 248)",
        fontWeight: 700,
        fontSize: "22px",
        lineHeight: "28px",
        paddingTop:"2rem",
        paddingLeft: "15px",
      }}
    >
      {t("Kako radi")}
      <hr style={{ color:"rgb(0, 198, 19)", borderBottom: "2px solid rgb(0, 198, 19)", width: "10rem"}} />
    </h2>
  );

  const text1 = (
    <ul
      style={{
        color: "rgb(199, 198, 216)",
        fontWeight: 400,
        fontSize: "18px",
        lineHeight: "30px",
        
      }}
    >
      <li>{t("Kreirajte zadatak za svaku aktivnost uz automatsku implementaciju u kalendar sa jasno podeljenim obavezama šta treba da se uradi.")}</li>
      <li>{t("Selektujte obaveze (prema potrebama) u odnosu na tip, status i datum zadatka (pomoću notifikacije) kako biste pratili korisne informacije.")}</li>
      <li>{t("Centralizujte razmenu informacija i aploudujte sve dokumenta i datoteke u odgovarajuće zadatke.")}</li>
      <li>{t("Automatizujte upozorenje u slučaju nedostatka odgovora na delegiran zadatak.")}</li>
    </ul>
  );
  const image1 = "./images/tablet.png";
  const imageStyle = {
    width: "100%", // Postavite širinu na 100% da bi slika popunila svoj roditeljski kontejner
    height: "auto", // Automatski prilagodite visinu kako bi se očuvao odnos širine i visine slike
    // Dodajte dodatne stilove prema potrebi
  };
  const titlesolution = <h2 style={{ color: "rgb(0,198,19)", textAlign: "left", fontWeight: "700", }}>{t("Solution")}</h2>;
  const titleproblem = <h2 style={{ color: "white", textAlign: "left", fontWeight: "700" }}>{t("Problem")}</h2>;
  const problemdescription = <p style={{ color: "white", textAlign: "left", fontWeight: "500" }}>{t("Konstatna pojava asimetrije informacija.")}</p>;
  const descriptionofsolution = <p style={{ color: "white", textAlign: "left",fontWeight: "500" }}>{t("Predvidite svaki korak do uspeha Objedinite i sinhronizujte rad svih struktura unutar kluba na jednom mestu.")}</p>;

  return (
    <div className={styles.mobilni}
      style={{
        backgroundColor: "#1d4e5b",
      }}
    >
      <h2
        style={{
          textAlign: "center",
          marginBottom: "30px",
          color: "rgb(255, 255, 255)",
          fontWeight: 700,
          fontSize: "34px",
          lineHeight: "41px",
        }}
      >
        {t("Plan Work")}
      </h2>
      <Row
        style={{
          justifyContent: "center",
          margin: "0 -10px",
          padding: "0",
          display: "flex",
          flexWrap: "wrap",
        
        }}
      >
        <Col
          xs={10}
          sm={6}
          md={4}
          className="card-container" /* Dodajemo klasu za stilove kartica */
          style={{
            marginBottom: "20px",
            display: "flex",
            justifyContent: "center", /* Horizontalno centriranje kartica */
            paddingLeft: "10px",
            paddingRight: "10px",
          }}
        >
          <Card title={titleproblem} description={problemdescription} />
        </Col>
        <Col
          xs={10}
          sm={6}
          md={4}
          className="card-container" /* Dodajemo klasu za stilove kartica */
          style={{
            marginBottom: "20px",
            display: "flex",
            justifyContent: "center", /* Horizontalno centriranje kartica */
            paddingLeft: "10px",
            paddingRight: "10px",
          }}
        >
          <Card title={titlesolution} description={descriptionofsolution} />
        </Col>

      </Row>

      <TextImageIsRight
        title={title1}
        text={text1}
        image={image1}
        showButton={false}
        // imageStyle={{ width: '100%', height: 'auto' }}
      />
    </div>
  );
};

export default PlanProjects;
