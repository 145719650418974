import React from "react";
import ImageWithBackground from "../Components/Solutions/ImageWithBackground ";
import SolutionHeader from "../Components/Solutions/SolutionHeader ";
import BenefitComponent from "../Components/Solutions/BenefitComponent ";
import TextImageLeft from "../Components/Solutions/TextImageLeft ";
import TextImageIsRight from "../Components/Solutions/TextImageIsRight";
import TextTwoImageleft from "../Components/Solutions/TextTwoImageleft"
import TextLeftTwoImgRight from "../Components/Solutions/TextLeftTwoImgRight"
import { Container, Row, Col, Button } from "react-bootstrap";
import CustomerReview from "../Components/CustomerReview";
import CustomButton from "../Components/CustomButton";
import { useTranslation } from "react-i18next";
import COFCTA from "../Components/COFCTA";
import styles from "./Solutions.module.css";
const Solutions = () => {
  const { t } = useTranslation();

  const title1 = (
    <h3 style={{ color: "white", textAlign: "left",}}>
      {t("Kalendari pregled obaveza")}
      <hr style={{ color:"rgb(0, 198, 19)", borderBottom: "2px solid rgb(0, 198, 19)", width: "10rem"}} />
    </h3>
  );
  const text1 = (
    <p style={{ color: "white", textAlign: "left", paddingTop: "0.5rem", paddingBottom: "1rem" }}>
      {t("Kalendari pregled obaveza-tekst")}
    </p>
  );
  const image1 = "/images/cof3.PNG";
  const imgKalendar = "/images/kalendarmob.png";
  const imgScheduled = "/images/scheduled1.png";
  const imgNotifications = "/images/notifications.png";
  const imgTrainers = "/images/trainers.png";
  const imgPlayers = "/images/players.png";
  const imgDaily = "/images/daily.png";

  const title2 = (
    <h3 style={{ color: "white", textAlign: "left"}}>
      {t("Pregled igračkog sastava")}
      <hr style={{ color:"rgb(0, 198, 19)", borderBottom: "2px solid rgb(0, 198, 19)", width: "10rem"}} />
    </h3>
  );
  const text2 = (
    <p style={{ color: "white", textAlign: "left", paddingTop: "0.5rem", paddingBottom: "1rem" }}>
      {t("Pregled igračkog sastava-tekst")}
    </p>
  );
  const image2 = "/images/cof3.PNG";

  const title3 = (
    <h3 style={{ color: "white", textAlign: "left"}}>
      {t("Praćenje dnevnog fizičkog i mentalnog stanja igrača")}
      <hr style={{ color:"rgb(0, 198, 19)", borderBottom: "2px solid rgb(0, 198, 19)", width: "10rem"}} />
    </h3>
  );
  const text3 = (
    <p style={{ color: "white", textAlign: "left", paddingTop: "0.5rem", paddingBottom: "1rem" }}>
      {t("Praćenje dnevnog fizičkog i mentalnog stanja igrača-tekst")}
    </p>
  );
  const image3 = "/images/cof3.PNG";

  const title4 = (
    <h3 style={{ color: "white", textAlign: "left"}}>
      {t("Automatizovana obaveštenja igračima")}
      <hr style={{ color:"rgb(0, 198, 19)", borderBottom: "2px solid rgb(0, 198, 19)", width: "10rem"}} />
    </h3>
  );
  const text4 = (
    <p style={{ color: "white", textAlign: "left", paddingTop: "0.5rem", paddingBottom: "1rem" }}>
      {t("Automatizovana obaveštenja igračima-tekst")}
    </p>
  );
  const image4 = "/images/dvatelefona.png";

  const title5 = (
    <h3 style={{ color: "white", textAlign: "left"}}>
      {t("Praćenje procesa napretka i oporavka igrača")}
      <hr style={{ color:"rgb(0, 198, 19)", borderBottom: "2px solid rgb(0, 198, 19)", width: "10rem"}} />
    </h3>
  );
  const text5 = (
    <p style={{ color: "white", textAlign: "left", paddingTop: "0.5rem", paddingBottom: "1rem" }}>
      {t("Praćenje procesa napretka i oporavka igrača-tekst")}
    </p>
  );
  const image5 = "/images/cof3.PNG";

  const title6 = (
    <h3 style={{ color: "white", textAlign: "left"}}>
      {t("Prevencija i predviđanje anomalija u procesima")}
      <hr style={{ color:"rgb(0, 198, 19)", borderBottom: "2px solid rgb(0, 198, 19)", width: "10rem"}} />
    </h3>
  );
  const text6 = (
    <p style={{ color: "white", textAlign: "left", paddingTop: "0.5rem", paddingBottom: "1rem" }}>
      {t("Prevencija i predviđanje anomalija u procesima-tekst")}
    </p>
  );
  const image6 = "/images/cof3.PNG";
  const logo = "./images/logosimple.PNG";
  return (
    <div>
      <div style={{ backgroundColor: "rgb(14,55,71)" }}><SolutionHeader /></div>
      <div className={styles.container}><ImageWithBackground />
        <div className={styles.upper}></div>
        <div className={styles.lower}></div>
      </div>
      <BenefitComponent />
      <Row
        style={{
          textAlign: "center",
          paddingTop: "100px",
          paddingBottom: "100px",
          backgroundColor: "rgb(14,55,71)",
        }}
      >
        <Col md={2}></Col>
        <Col className={styles.autor}>
          <h2 style={{ paddingBottom: "30px", paddingLeft: "5px", paddingRight: "5px", color: "white",fontStyle: "italic" }}>
            {t("today_through_daily_insight")}{" "}
            <span style={{ color: "green", fontStyle: "italic" }}>
              {t("club_actions_and_clinic_excluded_effects")}
            </span>{" "}
            {t("absence_of_unwanted_effects")}
          </h2>
          <h4 style={{ color: "white", fontStyle: "italic" }}>{t("testimonial_author")}</h4>
        </Col>
        <Col md={2}></Col>
      </Row>
      <div className={styles.mobilni}
        style={{
          backgroundColor: "#1d4e5b",

        }}
      >
        <TextTwoImageleft title={title1} text={text1} image1={imgKalendar} image2={imgScheduled} />
      </div>
      <div className={styles.mobilni}
        style={{
          backgroundColor: "rgb(14,55,71)",
        }}
      >
        <TextLeftTwoImgRight title={title2} text={text2} imageOne={imgTrainers} imageTwo={imgPlayers} />
      </div>
      <div className={styles.mobilni}
        style={{
          backgroundColor: "#1d4e5b",
        }}
      >
        <TextImageLeft title={title3} text={text3} image={image4} />
      </div>
      <div className={styles.mobilni}
        style={{
          backgroundColor: "rgb(14,55,71)",
        }}
      >
        <TextLeftTwoImgRight title={title4} text={text4} imageOne={imgNotifications} imageTwo={imgKalendar} />
      </div>
      <div className={styles.mobilni}
        style={{
          backgroundColor: "#1d4e5b",
        }}
      >
        <TextTwoImageleft title={title5} text={text5} image1={imgDaily} image2={imgPlayers} />
      </div>
      <div className={styles.mobilni}
        style={{
          backgroundColor: "rgb(14,55,71)",
        }}
      >
        <TextImageIsRight title={title6} text={text6} image={image4} />
      </div >
      <div style={{ backgroundColor: "#1d4e5b" }}><CustomerReview isPrimaryColor={false} /></div>
      <div style={{ backgroundColor: "rgb(14,55,71)" }}><COFCTA /></div>

    </div>
  );
};

export default Solutions;
