import React from "react";

const KeyValuesCard = ({ header, text, titleColor }) => {
  const styles = {
    card: {
      border: "",
      borderRadius: "20px",
      padding: "2rem 0.5rem",
      textAlign: "left",
      backgroundColor: "#0e3747",
      color: "white"
    },
    header: {
      fontSize: "20px",
      fontWeight: "bold",
      textTransform: "uppercase",
      marginBottom: "8px",
      color: titleColor // Use the provided titleColor prop for the title color
    },
    line: {
      borderBottom: `2px solid ${titleColor}`, // Draw a line with the provided color
      marginBottom: "8px", // Match the margin with the header
      width: "20%",
    },
    text: {
      color: "white"
    }
  };

  return (
    <div style={styles.card}>
      <div style={styles.header}>{header}</div>
      <div style={styles.line}></div>
      <div style={styles.text}>{text}</div>
    </div>
  );
};

export default KeyValuesCard;
