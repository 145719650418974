import React from "react";
import { Row,Col } from "react-bootstrap";
const StoryComponent = ({ header, text, icon }) => {
  return (
    <div style={styles.card}>
      <div style={styles.header}>
        <Row>
        <Col lg={9} xs={9}><h6>{header}</h6></Col>
        <Col lg={3} xs={3}><img src={icon} alt="icon" style={styles.icon} /></Col>
        </Row>
      
        
        
      </div>
      <div style={{ borderBottom: "2px solid #00c613", width:"20%",   paddingTop: "0rem", marginBottom: "0.75rem"}}></div>
      <h6 style={styles.text}>{text}</h6>
    </div>
  );
};

const styles = {
  card: {
    margin: "0px",
    color: "whitesmoke",
  },
  header: {
    fontWeight: "bold",
    textTransform: "uppercase",
    color: "#00c613",
  },
  text: {
    marginBottom: "",
    textAlign: "left", 
  },
  icon: {
    marginRight: "8px",
    width: "35px", // Adjust width and height as needed
    height: "35px",
    marginBottom:"10px",
    
  },
};

export default StoryComponent;
